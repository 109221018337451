let BACKEND_URI = "https://disinfodex-backend-production-e2kyhghera-ue.a.run.app"
let SYNC_URI = "https://disinfodex-sync-production-e2kyhghera-ue.a.run.app"

// BACKEND_URI = "http://localhost:5000"
// SYNC_URI = "http://localhost:3020"
SYNC_URI = "https://disinfodex.ue.r.appspot.com"

module.exports = {
    BACKEND_URI,
    SYNC_URI
}
